// deprecated?
export enum FirebaseCollection {
  ORGANIZATIONS = 'organizations',
  EVENTS = 'events',
  EVENT_CUSTOM_FIELDS = 'eventCustomFields',
  EVENT_TYPES = 'eventTypes',
  MEMBERS = 'members',
  MEMBER_CUSTOM_FIELDS = 'memberCustomFields',
}

// There must be a html input type available for these types
export enum CustomFieldType {
  TEXT = 'text',
  DATE = 'date',
  BOOLEAN = 'boolean',
}

export interface CustomField {
  id: string;
  name: string;
  type: CustomFieldType;
}

// These ABSOLUTELY HAVE TO be the same as the stripe product ids in the function
export enum StripeProductId {
  TESTPHASE = 'free-plan', // This plan is basically a basic plan, but only for the first 30 days
  DEACTIVATED = 'deactivated',
  BASIC = 'basic-plan',
  STANDARD = 'standard-plan',
  PREMIUM = 'premium-plan',
}

export interface Plan {
  productId: StripeProductId;
  cancelAtPeriodeEnd: boolean;
  grantingLevel: number; // This level defines the access to features. 0 = no access, 1 = basic, 2 = standard, 3 = premium
}

export interface StripePrice {
  id: string;
  active: boolean;
  currency: string;
  unit_amount: number;
  type: 'recurring' | 'one_time';
  recurring: {
    interval: 'day' | 'week' | 'month' | 'year';
    interval_count: number;
  };
  product: StripeProduct;
  lookup_key: string;
}

export interface StripeFeature {
  name: string;
}

export interface StripeProduct {
  id: string;
  active: boolean;
  name: string;
  prices: { monthly: StripePrice; yearly: StripePrice };
  features: StripeFeature[];
}

export interface StripeSubscription {
  id: string;
  cancel_at_period_end: boolean;
}
